import React, { useContext, useState } from "react";
import { AdminContext } from "../../Context/AdminContext";
import { FaBars, FaTimes, FaUser, FaEdit } from "react-icons/fa";
import { AiOutlineLogout } from "react-icons/ai";
import EditWhatsApp from "./EditWhatsApp";
import EditProducts from "./EditProducts";
import EditOrder from "./EditOrders";
import EditSuppliers from "./EditSuppliers";
import EditAdmins from "./EditAdmins";

const AdminPanel = () => {
  const { adminData, logout } = useContext(AdminContext);
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeComponent, setActiveComponent] = useState("home");

  const handleMenuToggle = () => {
    setMenuOpen((prevState) => !prevState);
  };

  const renderComponent = () => {
    switch (activeComponent) {
      case "edit-navbar":
        return <EditWhatsApp />;
      case "edit-products":
        return <EditProducts />;
      case "edit-orders":
        return <EditOrder />;
      case "edit-suppliers":
        return <EditSuppliers />;
      case "edit-admins":
        return <EditAdmins />;
      case "home":
      default:
        return (
          <div className="h-full bg-blue-100 rounded-lg shadow-lg p-8 flex items-center justify-center">
            <h1 className="text-4xl font-bold font-arial text-center text-gray-800">
              Administrador de Makiy Pets
            </h1>
          </div>
        );
    }
  };

  return (
    <div className="min-h-screen flex flex-col bg-gray-100">
      <header className="flex items-center justify-between p-4 bg-custom-purple text-white">
        <button onClick={handleMenuToggle}>
          {menuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
        </button>
        <div className="font-bold font-arial text-4xl text-center flex-1 ">
          Makiy Pets
        </div>
        <div className="flex items-center">
          <button
            onClick={logout}
            className="flex items-center space-x-2 p-2 bg-red-500 rounded-lg hover:bg-red-600 transition-all duration-300"
          >
            <AiOutlineLogout size={24} />
            <span className="hidden md:block">Cerrar Sesión</span>
          </button>
        </div>
      </header>

      <div className="flex flex-1 ">
        <aside
          className={`bg-white shadow-lg p-4 w-80 ${menuOpen ? "" : "hidden"}`}
        >
          <div className="flex items-center space-x-4 mb-4 p-4 bg-custom-orange rounded-lg shadow-md">
            <FaUser size={40} className="text-blue-900" />
            <span className="text-xl font-arial font-bold ">
              Administrador: {adminData.first_name} {adminData.last_name}
            </span>
          </div>
          <nav className="flex flex-col space-y-2">
            <button
              onClick={() => setActiveComponent("edit-navbar")}
              className="flex items-center justify-start space-x-2 p-2 bg-custom-purple text-white rounded-lg hover:bg-blue-700 transition-all duration-300"
            >
              <FaEdit size={20} />
              <span>Administrar WhatsApp</span>
            </button>
            <button
              onClick={() => setActiveComponent("edit-products")}
              className="flex items-center justify-start space-x-2 p-2 bg-custom-purple text-white rounded-lg hover:bg-blue-700 transition-all duration-300"
            >
              <FaEdit size={20} />
              <span>Administrar Productos</span>
            </button>

            <button
              onClick={() => setActiveComponent("edit-admins")}
              className="flex items-center justify-start space-x-2 p-2 bg-custom-purple text-white rounded-lg hover:bg-blue-700 transition-all duration-300"
            >
              <FaEdit size={20} />
              <span>Administrar Administradores</span>
            </button>
            <button
              onClick={() => setActiveComponent("edit-suppliers")}
              className="flex items-center justify-start space-x-2 p-2 bg-custom-purple text-white rounded-lg hover:bg-blue-700 transition-all duration-300"
            >
              <FaEdit size={20} />
              <span>Administrar Proveedores</span>
            </button>
            <button
              onClick={() => setActiveComponent("edit-orders")}
              className="flex items-center justify-start space-x-2 p-2 bg-custom-purple text-white rounded-lg hover:bg-blue-700 transition-all duration-300"
            >
              <FaEdit size={20} />
              <span>Administrar Órdenes</span>
            </button>
          </nav>
        </aside>
        <main className="flex-1 p-4">{renderComponent()}</main>
      </div>
    </div>
  );
};

export default AdminPanel;
