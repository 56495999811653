import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrash,
  faPlus,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import "react-toastify/dist/ReactToastify.css";
import {
  GET_ADMINS_API,
  PUT_ADMINS_API,
  DELETE_ADMINS_API,
  POST_ADMINS_API,
} from "../../config/Endpoints";

const EditAdmins = () => {
  const [admins, setAdmins] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingAdmin, setEditingAdmin] = useState("");
  const [addingAdmin, setAddingAdmin] = useState(false);
  const [newAdmin, setNewAdmin] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
  });
  const [currentPassword, setCurrentPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordFields, setShowPasswordFields] = useState(false); // NEW

  useEffect(() => {
    const fetchAdmins = async () => {
      setLoading(true);
      try {
        const response = await axios.get(GET_ADMINS_API);
        setAdmins(response.data.result);
      } catch (error) {
        setError(`Error fetching admins: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };
    fetchAdmins();
  }, []);

  const handleEditClick = (admin) => {
    setEditingAdmin(admin);
    setShowPasswordFields(false); // Reset visibility of password fields
    setShowPassword(false);
    setCurrentPassword("");
  };

  const handleInputChange = (e, setAdmin) => {
    const { name, value } = e.target;
    setAdmin((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSaveEdit = async () => {
    if (showPasswordFields) {
      // Comparar la nueva contraseña con la repetición de la nueva contraseña
      if (currentPassword !== editingAdmin.password) {
        toast.error("Las contraseñas no coinciden.");
        return;
      }
      if (!editingAdmin.password) {
        toast.error("La nueva contraseña no puede estar vacía.");
        return;
      }
    }

    try {
      const response = await axios.put(PUT_ADMINS_API, editingAdmin);
      const updatedAdmin = response.data.data;

      if (!updatedAdmin || !updatedAdmin.first_name) {
        throw new Error("Administrador actualizado no es válido.");
      }

      setAdmins(
        admins.map((admin) =>
          admin.id === editingAdmin.id ? updatedAdmin : admin
        )
      );
      setEditingAdmin("");
      toast.success("Administrador actualizado correctamente");
    } catch (error) {
      toast.error(`Error al actualizar el administrador`);
    }
  };

  const handleDeleteAdmin = async (adminId) => {
    try {
      await axios.delete(`${DELETE_ADMINS_API}/${adminId}`);
      setAdmins(admins.filter((admin) => admin.id !== adminId));
      toast.success("Administrador eliminado correctamente");
    } catch (error) {
      toast.error(`Error al eliminar el administrador`);
    }
  };

  const handleAddAdmin = async () => {
    if (Object.values(newAdmin).some((value) => value === "")) {
      toast.error("Todos los campos deben estar completos.");
      return;
    }

    try {
      const response = await axios.post(POST_ADMINS_API, newAdmin);
      const addedAdmin = response.data.data; // Asegúrate de que los datos están en response.data.data

      if (!addedAdmin || !addedAdmin.first_name) {
        throw new Error("Administrador añadido no es válido.");
      }

      setAdmins([...admins, addedAdmin]);
      setAddingAdmin(false);
      setNewAdmin({
        first_name: "",
        last_name: "",
        email: "",
        password: "",
      });
      toast.success("Administrador añadido correctamente");
    } catch (error) {
      toast.error(`Error al añadir el administrador: ${error.message}`);
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="edit-admins font-arial p-4">
      <ToastContainer />
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">Administrar Administradores</h2>
        <button
          onClick={() => setAddingAdmin(true)}
          className="bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700 transition duration-300"
        >
          <FontAwesomeIcon icon={faPlus} /> Añadir Administrador
        </button>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {admins.map((admin) => (
          <div
            key={admin.id}
            className="p-4 bg-white rounded-lg shadow-lg text-left"
          >
            <h3 className="font-bold">
              Nombre: {admin.first_name} {admin.last_name}
            </h3>
            <p>Email: {admin.email}</p>
            <p>Contraseña: {"*".repeat(admin.password.length)}</p>
            <div className="flex space-x-4 mt-2">
              <button
                onClick={() => handleEditClick(admin)}
                className="text-blue-500 text-lg"
              >
                <FontAwesomeIcon icon={faEdit} />
              </button>
              <button
                onClick={() => handleDeleteAdmin(admin.id)}
                className="text-red-500 text-lg"
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* Modal para editar administrador */}
      {editingAdmin && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg max-w-md w-full mx-2 md:mx-0 relative overflow-auto max-h-[calc(135vh-20rem)]">
            <button
              className="absolute top-2 right-2 text-gray-700 text-xl"
              onClick={() => setEditingAdmin(null)}
            >
              &times;
            </button>
            <h3 className="text-xl font-bold mb-4">Editar Administrador</h3>
            <div className="flex flex-col">
              <label className="block mb-2 font-semibold">Nombre</label>
              <input
                type="text"
                name="first_name"
                value={editingAdmin.first_name}
                onChange={(e) => handleInputChange(e, setEditingAdmin)}
                className="mb-4 p-2 border rounded bg-gray-200 w-full"
              />
              <label className="block mb-2 font-semibold">Apellido</label>
              <input
                type="text"
                name="last_name"
                value={editingAdmin.last_name}
                onChange={(e) => handleInputChange(e, setEditingAdmin)}
                className="mb-4 p-2 border rounded bg-gray-200 w-full"
              />
              <label className="block mb-2 font-semibold">Email</label>
              <input
                type="email"
                name="email"
                value={editingAdmin.email}
                onChange={(e) => handleInputChange(e, setEditingAdmin)}
                className="mb-4 p-2 border rounded bg-gray-200 w-full"
              />
              {showPasswordFields && (
                <>
                  <label className="block mb-2 font-semibold">
                    Nueva Contraseña
                  </label>
                  <input
                    type="password"
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    className="mb-4 p-2 border rounded bg-gray-200 w-full"
                  />
                  <label className="block mb-2 font-semibold">
                    Repita la nueva contraseña por favor
                  </label>
                  <input
                    type="password"
                    name="password"
                    value={editingAdmin.password}
                    onChange={(e) => handleInputChange(e, setEditingAdmin)}
                    className="mb-4 p-2 border rounded bg-gray-200 w-full"
                  />
                </>
              )}
              <button
                onClick={() => {
                  setShowPasswordFields(!showPasswordFields);
                  setEditingAdmin((prev) => ({ ...prev, password: "" })); // Asegura que el campo de nueva contraseña esté vacío
                }}
                className="text-blue-500 mb-4"
              >
                {showPasswordFields
                  ? "Cancelar Cambiar Contraseña"
                  : "Cambiar Contraseña"}
              </button>
              <button
                onClick={handleSaveEdit}
                className="bg-custom-purple  text-white py-2 px-4 rounded mt-4"
              >
                <FontAwesomeIcon icon={faSave} /> Guardar Cambios
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Modal para añadir administrador */}
      {addingAdmin && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg max-w-md w-full mx-2 md:mx-0 relative overflow-auto max-h-[calc(135vh-20rem)]">
            <button
              className="absolute top-2 right-2 text-gray-700 text-xl"
              onClick={() => setAddingAdmin(false)}
            >
              &times;
            </button>
            <h3 className="text-xl font-bold mb-4">
              Añadir Nuevo Administrador
            </h3>
            <div className="flex flex-col">
              <label className="block mb-2 font-semibold">Nombre</label>
              <input
                type="text"
                name="first_name"
                value={newAdmin.first_name}
                onChange={(e) => handleInputChange(e, setNewAdmin)}
                className="mb-4 p-2 border rounded bg-gray-200 w-full"
              />
              <label className="block mb-2 font-semibold">Apellido</label>
              <input
                type="text"
                name="last_name"
                value={newAdmin.last_name}
                onChange={(e) => handleInputChange(e, setNewAdmin)}
                className="mb-4 p-2 border rounded bg-gray-200 w-full"
              />
              <label className="block mb-2 font-semibold">Email</label>
              <input
                type="email"
                name="email"
                value={newAdmin.email}
                onChange={(e) => handleInputChange(e, setNewAdmin)}
                className="mb-4 p-2 border rounded bg-gray-200 w-full"
              />
              <label className="block mb-2 font-semibold">Contraseña</label>
              <input
                type="password"
                name="password"
                value={newAdmin.password}
                onChange={(e) => handleInputChange(e, setNewAdmin)}
                className="mb-4 p-2 border rounded bg-gray-200 w-full"
              />
              <button
                onClick={handleAddAdmin}
                className="bg-green-500 text-white py-2 px-4 rounded mt-4"
              >
                <FontAwesomeIcon icon={faSave} /> Guardar Administrador
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditAdmins;
