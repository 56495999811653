import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import "./App.css";
import Main from "./Components/Main/Main";
import AboutUs from "./Components/AboutUs/AboutUs";
import Footer from "./Components/Footer/Footer";
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";
import Navbar from "./Components/Navbar/Navbar";
import PetFoods from "./Components/PetFoods/PetFoods";
import Policies from "./Components/Policies/Policies";
import Login from "./Components/Admin/Login";
import AdminPanel from "./Components/Admin/AdminPanel";
import ProtectedRoute from "./Components/Admin/ProtectedRoute";
import { AdminProvider } from "./Context/AdminContext";

function App() {
  const location = useLocation();

  const shouldShowFooter = (pathname) => {
    const noFooterPaths = ["/admin"];
    return !noFooterPaths.some((path) => pathname.startsWith(path));
  };

  return (
    <AdminProvider>
      <ScrollToTop />
      <div className="App">
        <Routes>
          <Route
            exact
            path="/"
            element={
              <>
                <Navbar />
                <Main />
              </>
            }
          />
          <Route
            exact
            path="/home"
            element={
              <>
                <Navbar />
                <Main />
              </>
            }
          />
          <Route
            path="/about"
            element={
              <>
                <Navbar />
                <AboutUs />
              </>
            }
          />
          <Route
            path="/pet-foods"
            element={
              <>
                <Navbar />
                <PetFoods />
              </>
            }
          />
          <Route
            path="/policies"
            element={
              <>
                <Navbar />
                <Policies />
              </>
            }
          />
          <Route
            path="/petfoods/:category"
            element={
              <>
                <Navbar />
                <PetFoods />
              </>
            }
          />
          <Route
            path="/login"
            element={
              <>
                <Navbar />
                <Login />
              </>
            }
          />
          <Route
            path="/admin"
            element={
              <ProtectedRoute>
                <AdminPanel />
              </ProtectedRoute>
            }
          />
        </Routes>
        {shouldShowFooter(location.pathname) && <Footer />}
      </div>
    </AdminProvider>
  );
}

function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWrapper;
