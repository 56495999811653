import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrash,
  faPlus,
  faSave,
  faFilter,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import {
  GET_PRODUCTS_API,
  ADD_PRODUCT_API,
  UPDATE_PRODUCT_API,
  DELETE_PRODUCT_API,
  UPLOAD_IMAGE_API,
} from "../../config/Endpoints";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const uploadImageToBackend = async (file) => {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onloadend = async () => {
      const base64File = reader.result.split(",")[1];
      try {
        const response = await axios.post(UPLOAD_IMAGE_API, {
          file: base64File,
          fileName: file.name,
          fileType: file.type,
        });
        resolve(response.data.url);
      } catch (error) {
        reject(error);
      }
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

const EditProducts = () => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editProduct, setEditProduct] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const [newProduct, setNewProduct] = useState({
    name: "",
    description: "",
    price: "",
    image: "",
    available: true,
    category_id: "",
    supplier_id: "",
    stock: "",
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [filterCategory, setFilterCategory] = useState("");

  const fetchProducts = async () => {
    setLoading(true);
    try {
      const response = await axios.get(GET_PRODUCTS_API);
      const allProducts = response.data.result;

      if (!Array.isArray(allProducts)) {
        throw new Error("Expected an array of products");
      }

      setProducts(allProducts);
      setFilteredProducts(allProducts);
    } catch (error) {
      setError(`Error fetching products: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const filterProducts = useCallback(() => {
    let tempProducts = products;

    if (searchTerm) {
      tempProducts = tempProducts.filter((product) =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (filterCategory && filterCategory !== "todos") {
      tempProducts = tempProducts.filter(
        (product) => product.category_id === filterCategory
      );
    }

    setFilteredProducts(tempProducts);
  }, [products, searchTerm, filterCategory]);

  useEffect(() => {
    filterProducts();
  }, [filterProducts]);

  const handleEditProduct = (product) => {
    setIsEditing(true);
    setEditProduct(product);
  };

  const handleSaveEdit = async () => {
    if (Object.values(editProduct).some((value) => value === "")) {
      toast.error("Todos los campos deben estar completos.");
      return;
    }

    if (isNaN(editProduct.price) || editProduct.price < 0) {
      toast.error(
        "El precio debe ser un número válido y no puede ser menor a $0"
      );
      return;
    }

    if (isNaN(editProduct.stock) || editProduct.stock < 0) {
      toast.error(
        "El stock debe ser un número válido y no puede ser menor a 0"
      );
      return;
    }

    const updatedProduct = {
      ...editProduct,
      price: parseFloat(editProduct.price),
      stock: parseInt(editProduct.stock, 10),
    };

    try {
      if (updatedProduct.image instanceof File) {
        updatedProduct.image = await uploadImageToBackend(updatedProduct.image);
      }
      await axios.put(UPDATE_PRODUCT_API, updatedProduct);
      setProducts(
        products.map((product) =>
          product.id === editProduct.id ? updatedProduct : product
        )
      );
      setIsEditing(false);
      setEditProduct(null);
      toast.success("Producto actualizado correctamente");
    } catch (error) {
      toast.error(`Error al actualizar el producto: ${error.message}`);
    }
  };

  const handleDeleteProduct = async (productId) => {
    try {
      await axios.delete(`${DELETE_PRODUCT_API}/${productId}`);
      setProducts(products.filter((product) => product.id !== productId));
      toast.success("Producto eliminado correctamente");
    } catch (error) {
      toast.error(`Error al eliminar el producto: ${error.message}`);
    }
  };

  const handleAddProduct = async () => {
    if (Object.values(newProduct).some((value) => value === "")) {
      toast.error("Todos los campos deben estar completos.");
      return;
    }

    if (isNaN(newProduct.price) || newProduct.price < 0) {
      toast.error(
        "El precio debe ser un número válido y no puede ser menor a $0"
      );
      return;
    }

    if (isNaN(newProduct.stock) || newProduct.stock < 0) {
      toast.error(
        "El stock debe ser un número válido y no puede ser menor a 0"
      );
      return;
    }

    const newProductData = {
      ...newProduct,
      price: parseFloat(newProduct.price),
      stock: parseInt(newProduct.stock, 10),
    };

    try {
      if (newProductData.image instanceof File) {
        newProductData.image = await uploadImageToBackend(newProductData.image);
      }
      await axios.post(ADD_PRODUCT_API, newProductData);
      await fetchProducts();
      setIsAdding(false);
      setNewProduct({
        name: "",
        description: "",
        price: "",
        image: "",
        available: true,
        category_id: "",
        supplier_id: "",
        stock: "",
      });
      toast.success("Producto añadido correctamente");
    } catch (error) {
      toast.error(`Error al añadir el producto: ${error.message}`);
    }
  };

  const handleChange = (e, setState) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleImageUpload = (e, setState) => {
    const file = e.target.files[0];
    if (file) {
      setState((prevState) => ({ ...prevState, image: file }));
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCategoryFilterChange = (e) => {
    setFilterCategory(e.target.value);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="edit-products font-arial p-4">
      <ToastContainer />
      <h2 className="text-2xl font-bold text-center mb-4">
        Administrar Productos
      </h2>
      <div className="flex justify-between items-center mb-4">
        <button
          onClick={() => setIsAdding(true)}
          className="add-button bg-custom-orange  text-white py-2 px-4 rounded hover:bg-orange-400 transition duration-300"
        >
          <FontAwesomeIcon icon={faPlus} /> Añadir Producto
        </button>
        <div className="flex items-center">
          <div className="relative mr-2">
            <select
              value={filterCategory}
              onChange={handleCategoryFilterChange}
              className="input-field p-2 border rounded bg-gray-200 hover:bg-gray-300 transition duration-300"
            >
              <option value="todos">Todos </option>
              <option value="perros">Perros </option>
              <option value="gatos">Gatos </option>
            </select>
            <FontAwesomeIcon
              icon={faFilter}
              className="absolute top-3 right-3 text-gray-500"
            />
          </div>
          <div className="relative">
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearchChange}
              className="input-field p-2 border rounded bg-gray-200 hover:bg-gray-300 transition duration-300"
              placeholder="Buscar producto.."
            />
            <FontAwesomeIcon
              icon={faSearch}
              className="absolute top-3 right-3 text-gray-500"
            />
          </div>
        </div>
      </div>
      <div className="products-grid mb-6">
        {filteredProducts.map((product) => (
          <div key={product.id} className="product-card">
            <img
              src={product.image}
              alt={product.name}
              className="product-image"
            />
            <h3 className="product-name font-bold">{product.name}</h3>
            <p className="product-description">{product.description}</p>
            <p className="product-price">
               ${parseFloat(product.price).toFixed(2)} (inc. IVA)
            </p>
            <p className="product-stock font-arial text-orange-500">
              Stock: {product.stock}
            </p>
            <div className="product-buttons">
              <button
                className="edit-button"
                onClick={() => handleEditProduct(product)}
              >
                <FontAwesomeIcon icon={faEdit} />
              </button>
              <button
                className="delete-button"
                onClick={() => handleDeleteProduct(product.id)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </div>
          </div>
        ))}
      </div>

      {isEditing && editProduct && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg max-w-md w-full mx-2 md:mx-0 relative overflow-auto max-h-[calc(135vh-20rem)] ">
            <button
              className="absolute top-2 right-2 text-gray-700 text-xl"
              onClick={() => setIsEditing(false)}
            >
              &times;
            </button>
            <h3 className="text-xl font-bold mb-4 bg-blue-100">
              Editar Producto
            </h3>
            <div className="flex flex-col">
              <label className="block mb-2 font-semibold">
                Nombre del Producto
              </label>
              <input
                type="text"
                name="name"
                value={editProduct.name}
                onChange={(e) => handleChange(e, setEditProduct)}
                className="input-field mb-4 p-2 border rounded bg-gray-200 w-full"
                placeholder="Nombre del Producto"
              />
              <label className="block mb-2 font-semibold">
                Descripción del Producto
              </label>
              <textarea
                name="description"
                value={editProduct.description}
                onChange={(e) => handleChange(e, setEditProduct)}
                className="input-field mb-4 p-2 border rounded bg-gray-200 w-full"
                placeholder="Descripción del Producto"
              />
              <label className="block mb-2 font-semibold">
                Categoría del Producto
              </label>
              <select
                name="category_id"
                value={editProduct.category_id}
                onChange={(e) => handleChange(e, setEditProduct)}
                className="input-field mb-4 p-2 border rounded bg-gray-200 w-full"
              >
                <option value="">Seleccione una categoría</option>
                <option value="perros">Perros</option>
                <option value="gatos">Gatos</option>
              </select>
              <label className="block mb-2 font-semibold">
                Proveedor del Producto
              </label>
              <textarea
                name="supplier_id"
                value={editProduct.supplier_id}
                onChange={(e) => handleChange(e, setEditProduct)}
                className="input-field mb-4 p-2 border rounded bg-gray-200 w-full"
                placeholder="Proveedor del Producto"
              />
              <label className="block mb-2 font-semibold">
                Precio del Producto
              </label>
              <input
                type="number"
                name="price"
                value={editProduct.price}
                onChange={(e) => handleChange(e, setEditProduct)}
                className="input-field mb-4 p-2 border rounded bg-gray-200 w-full"
                placeholder="Precio del Producto"
              />
              <label className="block mb-2 font-semibold">
                Stock Disponible
              </label>
              <input
                type="number"
                name="stock"
                value={editProduct.stock}
                onChange={(e) => handleChange(e, setEditProduct)}
                className="input-field mb-4 p-2 border rounded bg-gray-200 w-full"
                placeholder="Stock del Producto"
              />
              <label className="block mb-2 font-semibold">
                Imagen del Producto
              </label>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleImageUpload(e, setEditProduct)}
                className="input-field mb-4 p-2 border rounded bg-gray-200 w-full"
              />
              <div className="flex items-center space-x-2 mb-4">
                <label className="font-arial">Disponible:</label>
                <input
                  type="checkbox"
                  name="available"
                  checked={editProduct.available}
                  onChange={(e) =>
                    setEditProduct({
                      ...editProduct,
                      available: e.target.checked,
                    })
                  }
                />
              </div>
              <button
                onClick={handleSaveEdit}
                className="save-button bg-custom-purple  text-white py-2 px-4 rounded"
              >
                <FontAwesomeIcon icon={faSave} /> Guardar Cambios
              </button>
            </div>
          </div>
        </div>
      )}

      {isAdding && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg max-w-md w-full mx-2 md:mx-0 relative overflow-auto max-h-[calc(135vh-20rem)]">
            <button
              className="absolute top-2 right-2 text-gray-700 text-xl"
              onClick={() => setIsAdding(false)}
            >
              &times;
            </button>
            <h3 className="text-xl font-bold mb-4 bg-blue-100">
              Añadir Nuevo Producto
            </h3>
            <div className="flex flex-col">
              <label className="block mb-2 font-semibold">
                Nombre del Producto
              </label>
              <input
                type="text"
                name="name"
                value={newProduct.name}
                onChange={(e) => handleChange(e, setNewProduct)}
                className="input-field mb-4 p-2 border rounded bg-gray-200 w-full"
                placeholder="Nombre del Producto"
              />
              <label className="block mb-2 font-semibold">
                Descripción del Producto
              </label>
              <textarea
                name="description"
                value={newProduct.description}
                onChange={(e) => handleChange(e, setNewProduct)}
                className="input-field mb-4 p-2 border rounded bg-gray-200 w-full"
                placeholder="Descripción del Producto"
              />
              <label className="block mb-2 font-semibold">
                Precio del Producto
              </label>
              <input
                type="number"
                name="price"
                value={newProduct.price}
                onChange={(e) => handleChange(e, setNewProduct)}
                className="input-field mb-4 p-2 border rounded bg-gray-200 w-full"
                placeholder="Precio del Producto"
              />
              <label className="block mb-2 font-semibold">
                Imagen del Producto
              </label>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleImageUpload(e, setNewProduct)}
                className="input-field mb-4 p-2 border rounded bg-gray-200 w-full"
              />
              <label className="block mb-2 font-semibold">
                Categoría del Producto
              </label>
              <select
                name="category_id"
                value={newProduct.category_id}
                onChange={(e) => handleChange(e, setNewProduct)}
                className="input-field mb-4 p-2 border rounded bg-gray-200 w-full"
              >
                <option value="">Seleccione una categoría</option>
                <option value="perros">Perros</option>
                <option value="gatos">Gatos</option>
              </select>
              <label className="block mb-2 font-semibold">
                Proveedor del Producto
              </label>
              <input
                type="text"
                name="supplier_id"
                value={newProduct.supplier_id}
                onChange={(e) => handleChange(e, setNewProduct)}
                className="input-field mb-4 p-2 border rounded bg-gray-200 w-full"
                placeholder="Proveedor del Producto"
              />
              <label className="block mb-2 font-semibold">
                Stock del Producto
              </label>
              <input
                type="number"
                name="stock"
                value={newProduct.stock}
                onChange={(e) => handleChange(e, setNewProduct)}
                className="input-field mb-4 p-2 border rounded bg-gray-200 w-full"
                placeholder="Stock del Producto"
              />
              <div className="flex items-center space-x-2 mb-4">
                <label className="font-arial">Disponible:</label>
                <input
                  type="checkbox"
                  name="available"
                  checked={newProduct.available}
                  onChange={(e) =>
                    setNewProduct({
                      ...newProduct,
                      available: e.target.checked,
                    })
                  }
                />
              </div>
              <button
                onClick={handleAddProduct}
                className="add-button bg-custom-purple  text-white py-2 px-4 rounded"
              >
                <FontAwesomeIcon icon={faSave} /> Añadir Producto
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditProducts;
