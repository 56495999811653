import React, { useEffect, useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrash,
  faPlus,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import {
  GET_SUPPLIERS_API,
  POST_SUPPLIERS_API,
  PUT_SUPPLIERS_API,
  DELETE_SUPPLIERS_API,
} from "../../config/Endpoints";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EditSuppliers = () => {
  const [suppliers, setSuppliers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editSupplier, setEditSupplier] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const [newSupplier, setNewSupplier] = useState({
    name: "",
    address: "",
    email: "",
    phone: "",
    website: "",
  });

  const fetchSuppliers = async () => {
    setLoading(true);
    try {
      const response = await axios.get(GET_SUPPLIERS_API);
      setSuppliers(response.data.result || []);
    } catch (error) {
      setError(`Error al obtener proveedores: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSuppliers();
  }, []);

  const handleEditSupplier = (supplier) => {
    setIsEditing(true);
    setEditSupplier(supplier);
  };

  const handleSaveEdit = async () => {
    if (Object.values(editSupplier).some((value) => value.trim() === "")) {
      toast.error("Todos los campos deben estar completos.");
      return;
    }

    try {
      await axios.put(PUT_SUPPLIERS_API, editSupplier);

      // Asegúrate de que editSupplier tiene los valores correctos antes de actualizar el estado
      if (
        editSupplier.name &&
        editSupplier.address &&
        editSupplier.email &&
        editSupplier.phone &&
        editSupplier.website
      ) {
        setSuppliers((prevSuppliers) =>
          prevSuppliers.map((supplier) =>
            supplier.id === editSupplier.id ? editSupplier : supplier
          )
        );
      }

      setIsEditing(false);
      setEditSupplier(null);
      toast.success("Proveedor actualizado correctamente");
    } catch (error) {
      toast.error(`Error al actualizar el proveedor: ${error.message}`);
    }
  };

  const handleDeleteSupplier = async (supplierId) => {
    try {
      await axios.delete(`${DELETE_SUPPLIERS_API}/${supplierId}`);
      setSuppliers(suppliers.filter((supplier) => supplier.id !== supplierId));
      toast.success("Proveedor eliminado correctamente");
    } catch (error) {
      toast.error(`Error al eliminar el proveedor: ${error.message}`);
    }
  };

  const handleAddSupplier = async () => {
    if (Object.values(newSupplier).some((value) => value === "")) {
        toast.error("Todos los campos deben estar completos.");
        return;
    }

    try {
        const response = await axios.post(POST_SUPPLIERS_API, newSupplier);

        // Acceder al proveedor agregado en la estructura correcta
        const addedSupplier = response.data.data;

        // Verificar que el proveedor agregado tenga los datos correctos
        if (!addedSupplier || !addedSupplier.name) {
            throw new Error("Proveedor añadido no es válido.");
        }

        // Actualizar el estado con el nuevo proveedor
        setSuppliers((prevSuppliers) => [...prevSuppliers, addedSupplier]);

        // Restablecer el formulario de nuevo proveedor
        setIsAdding(false);
        setNewSupplier({
            name: "",
            address: "",
            email: "",
            phone: "",
            website: "",
        });
        toast.success("Proveedor añadido correctamente");
    } catch (error) {
        toast.error(`Error al añadir el proveedor: ${error.message}`);
    }
};


  const handleChange = (e, setState) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="edit-suppliers font-arial p-4">
      <ToastContainer />
      <h2 className="text-2xl font-bold text-center mb-4">
        Administrar Proveedores
      </h2>
      <div className="flex justify-between items-center mb-4">
        <button
          onClick={() => setIsAdding(true)}
          className="bg-custom-orange  text-white py-2 px-4 rounded hover:bg-orange-400 transition duration-300"
        >
          <FontAwesomeIcon icon={faPlus} /> Añadir Proveedor
        </button>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {suppliers.map((supplier) => (
          supplier && (
            <div
              key={supplier.id}
              className="p-4 bg-white rounded-lg shadow-lg text-left"
            >
              <h3 className="font-bold">Proveedor: {supplier.name}</h3>
              <p>Dirección: {supplier.address}</p>
              <p>Email: {supplier.email}</p>
              <p>Número de celular: {supplier.phone}</p>
              <p>Sitio web: {supplier.website}</p>
              <div className="flex space-x-4 mt-2">
                <button
                  onClick={() => handleEditSupplier(supplier)}
                  className="text-blue-500 text-lg"
                >
                  <FontAwesomeIcon icon={faEdit} />
                </button>
                <button
                  onClick={() => handleDeleteSupplier(supplier.id)}
                  className="text-red-500 text-lg"
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </div>
            </div>
          )
        ))}
      </div>

      {isEditing && editSupplier && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg max-w-md w-full mx-2 md:mx-0 relative overflow-auto max-h-[calc(135vh-20rem)]">
            <button
              className="absolute top-2 right-2 text-gray-700 text-xl"
              onClick={() => setIsEditing(false)}
            >
              &times;
            </button>
            <h3 className="text-xl font-bold mb-4 bg-blue-100">
              Editar Proveedor
            </h3>
            <div className="flex flex-col">
              <label className="block mb-2 font-semibold">Nombre</label>
              <input
                type="text"
                name="name"
                value={editSupplier.name}
                onChange={(e) => handleChange(e, setEditSupplier)}
                className="mb-4 p-2 border rounded bg-gray-200 w-full"
              />
              <label className="block mb-2 font-semibold">Dirección</label>
              <input
                type="text"
                name="address"
                value={editSupplier.address}
                onChange={(e) => handleChange(e, setEditSupplier)}
                className="mb-4 p-2 border rounded bg-gray-200 w-full"
              />
              <label className="block mb-2 font-semibold">Email</label>
              <input
                type="email"
                name="email"
                value={editSupplier.email}
                onChange={(e) => handleChange(e, setEditSupplier)}
                className="mb-4 p-2 border rounded bg-gray-200 w-full"
              />
              <label className="block mb-2 font-semibold">Teléfono</label>
              <input
                type="text"
                name="phone"
                value={editSupplier.phone}
                onChange={(e) => handleChange(e, setEditSupplier)}
                className="mb-4 p-2 border rounded bg-gray-200 w-full"
              />
              <label className="block mb-2 font-semibold">Sitio web</label>
              <input
                type="text"
                name="website"
                value={editSupplier.website}
                onChange={(e) => handleChange(e, setEditSupplier)}
                className="mb-4 p-2 border rounded bg-gray-200 w-full"
              />
              <button
                onClick={handleSaveEdit}
                className="bg-custom-purple  text-white py-2 px-4 rounded"
              >
                <FontAwesomeIcon icon={faSave} /> Guardar Cambios
              </button>
            </div>
          </div>
        </div>
      )}

      {isAdding && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg max-w-md w-full mx-2 md:mx-0 relative overflow-auto max-h-[calc(135vh-20rem)]">
            <button
              className="absolute top-2 right-2 text-gray-700 text-xl"
              onClick={() => setIsAdding(false)}
            >
              &times;
            </button>
            <h3 className="text-xl font-bold mb-4 bg-blue-100">
              Añadir Nuevo Proveedor
            </h3>
            <div className="flex flex-col">
              <label className="block mb-2 font-semibold">Nombre</label>
              <input
                type="text"
                name="name"
                value={newSupplier.name}
                onChange={(e) => handleChange(e, setNewSupplier)}
                className="mb-4 p-2 border rounded bg-gray-200 w-full"
              />
              <label className="block mb-2 font-semibold">Dirección</label>
              <input
                type="text"
                name="address"
                value={newSupplier.address}
                onChange={(e) => handleChange(e, setNewSupplier)}
                className="mb-4 p-2 border rounded bg-gray-200 w-full"
              />
              <label className="block mb-2 font-semibold">Email</label>
              <input
                type="email"
                name="email"
                value={newSupplier.email}
                onChange={(e) => handleChange(e, setNewSupplier)}
                className="mb-4 p-2 border rounded bg-gray-200 w-full"
              />
              <label className="block mb-2 font-semibold">Teléfono</label>
              <input
                type="text"
                name="phone"
                value={newSupplier.phone}
                onChange={(e) => handleChange(e, setNewSupplier)}
                className="mb-4 p-2 border rounded bg-gray-200 w-full"
              />
              <label className="block mb-2 font-semibold">Sitio web</label>
              <input
                type="text"
                name="website"
                value={newSupplier.website}
                onChange={(e) => handleChange(e, setNewSupplier)}
                className="mb-4 p-2 border rounded bg-gray-200 w-full"
              />
              <button
                onClick={handleAddSupplier}
                className="bg-custom-purple  text-white py-2 px-4 rounded"
              >
                <FontAwesomeIcon icon={faSave} /> Añadir Proveedor
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditSuppliers;
