import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faShoppingBag,
  faTruck,
  faHeadset,
} from "@fortawesome/free-solid-svg-icons";
import "../../Components/Slider/Slider.css";

const images = [
  require("../../assets/images/slider1.png"),
  require("../../assets/images/slider3.png"),
];

const Slider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="slider-container">
      <div className="slider relative">
        <div className="slider-image-container">
          <img
            src={images[currentIndex]}
            alt="Slider"
            className="slider-image hover-effect"
          />
          <button className="slider-button left" onClick={prevSlide}>
            <FontAwesomeIcon icon={faChevronLeft} className="w-6 h-6" />
          </button>
          <button className="slider-button right" onClick={nextSlide}>
            <FontAwesomeIcon icon={faChevronRight} className="w-6 h-6" />
          </button>
        </div>
        <div className="new-section">
          <br />
          <div className="feature">
            <FontAwesomeIcon icon={faShoppingBag} className="feature-icon" />
            <p className="feature-text font-arial text-5xl font-bold text">
              COMPRA EN LÍNEA
              <br />
              <span className="subtitle">
                <br />
                Escoge de nuestra lista de productos de gran variedad.
              </span>
            </p>
          </div>
          <div className="feature">
            <FontAwesomeIcon icon={faTruck} className="feature-icon" />
            <p className="feature-text font-arial text-5xl font-bold">
              A DOMICILIO
              <br />
              <span className="subtitle">
                <br />
                Entrega rápida y garantizada a donde usted nos necesite.
              </span>
            </p>
          </div>
          <div className="feature">
            <FontAwesomeIcon icon={faHeadset} className="feature-icon" />
            <p className="feature-text font-arial text-5xl font-bold">
              ATENCIÓN DE PRIMERA
              <br />
              <span className="subtitle">
                <br />
                Nos especializamos en su satisfacción y la de su mascota.
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider;
