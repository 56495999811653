// Components/Admin/Login.jsx
import React, { useState, useContext } from "react";
import axios from "axios";
import loginBackground from "../../assets/images/login.png";
import { AdminContext } from "../../Context/AdminContext";
import { useNavigate } from "react-router-dom";
import { GET_ADMINS_API } from "../../config/Endpoints";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const { login } = useContext(AdminContext);
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const response = await axios.get(GET_ADMINS_API);
      const admins = response.data.result;
      const admin = admins.find(
        (admin) => admin.email === email && admin.password === password
      );

      if (admin) {
        setMessage(`Bienvenido ${admin.first_name} ${admin.last_name}`);
        login(admin); // Llamar a la función login del contexto con los datos del administrador
        navigate("/admin"); // Redirigir al panel de administración
      } else {
        setMessage("Correo o contraseña incorrectos.");
      }
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }

    setTimeout(() => {
      setMessage("");
    }, 4000);
  };

  const handleForgotPassword = async () => {
    try {
      const response = await axios.get(GET_ADMINS_API);
      const admins = response.data.result;
      const admin = admins.find((admin) => admin.email === email);

      if (admin) {
        setMessage(
          "Si usted ha olvidado su contraseña, por favor comunicarse con msordonez@puce.edu.ec"
        );
      } else {
        setMessage("El correo no está registrado.");
      }
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }

    setTimeout(() => {
      setMessage("");
    }, 4000);
  };

  const clearFields = () => {
    setEmail("");
    setPassword("");
  };

  const toggleForgotPassword = () => {
    setIsForgotPassword(!isForgotPassword);
    clearFields();
  };

  return (
    <div
      className="flex flex-col items-center justify-center min-h-screen bg-cover bg-center font-arial"
      style={{ backgroundImage: `url(${loginBackground})` }}
    >
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md bg-opacity-90">
        <h1 className="text-4xl font-bold mb-6 text-center text-gray-800">
          Gestión Administrativa
        </h1>
        <h2 className="text-2xl font-semibold mb-6 text-center text-gray-700">
          {isForgotPassword ? "Recuperar Contraseña" : ""}
        </h2>
        <input
          type="email"
          placeholder="Correo Electrónico"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="w-full p-3 mb-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-600"
        />
        {!isForgotPassword && (
          <input
            type="password"
            placeholder="Contraseña"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full p-3 mb-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-600"
          />
        )}
        <button
          onClick={isForgotPassword ? handleForgotPassword : handleLogin}
          className="w-full p-3 mb-4 bg-custom-orange text-white rounded-lg hover:bg-orange-600 transition-all duration-300"
        >
          {isForgotPassword ? "Recuperar Contraseña" : "Iniciar Sesión"}
        </button>
        <button
          onClick={toggleForgotPassword}
          className="w-full p-3 bg-custom-purple text-white rounded-lg hover:bg-blue-800 transition-all duration-300"
        >
          {isForgotPassword ? "Volver al Login" : "He olvidado mi contraseña"}
        </button>
        {message && (
          <p className="mt-4 text-center text-red-600 font-semibold">
            {message}
          </p>
        )}
      </div>
    </div>
  );
};

export default Login;
